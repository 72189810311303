import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"vary":"Accept-Encoding","cross-origin-resource-policy":"cross-origin","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","date":"Thu, 13 Feb 2025 20:12:41 GMT","cf-ray":"91177987ba7e24f3-SJC","x-jsd-version":"1.0.8","x-cache":"HIT, HIT","access-control-expose-headers":"*","timing-allow-origin":"*","strict-transport-security":"max-age=31536000; includeSubDomains; preload","x-jsd-version-type":"version","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","content-type":"text/plain; charset=utf-8","age":"32194","accept-ranges":"bytes","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=hOkLOupwU8sP86T%2B20%2BbHn3mANs3W02ferF1cpQuct50h8j5alZnw6ppHUO3Br90V9QScqYMz8khnekk4oByr21grkRTKXPWdzArh0PyNif7jwvnIfjzVHAcXEtoAw9eSdw%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21947-LGA","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","cf-cache-status":"HIT","server":"cloudflare","access-control-allow-origin":"*","x-content-type-options":"nosniff","alt-svc":"h3=\":443\"; ma=86400"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1739477561}